/* eslint camelcase: ["error", {allow: ["UNSAFE_componentWillMount"]}] */

import React, { Fragment } from 'react'
import { checkIfClientVisitedCreditCardPage, getClientLastVisitData } from './base'

/*
 * Logout static component
 */
export default class LogoutBannerStatic extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      banner: 'random',
      pageName: null
    }
  }

  UNSAFE_componentWillMount () {
    const prospectStatus = 'unrecognized'
    const userStatus = document.cookie.replace(/(?:(?:^|.*;\s*)lastAmpAuthState\s*=\s*([^;]*).*$)|^.*$/, '$1') || prospectStatus

    if (!userStatus || (userStatus && userStatus === prospectStatus)) {
      return this.setState({ banner: 'random' })
    } else {
      if (checkIfClientVisitedCreditCardPage()) {
        const clientData = getClientLastVisitData()
        if (clientData && clientData.length) {
          const filteredClientData = clientData.filter((item) => {
            return item.hasVisited === true && item.hasButtonClicked === true
          })
          if (filteredClientData && filteredClientData.length) {
            return this.setState({ banner: 'random' })
          } else {
            const filteredClientVisitData = clientData.filter((item) => {
              return item.hasVisited === true && item.timestamp > 0
            })
            if (filteredClientVisitData && filteredClientVisitData.length) {
              const visitInfo = filteredClientVisitData[0]
              return this.setState({ banner: 'variant', pageName: visitInfo.pagename })
            }
          }
        } else {
          return this.setState({ banner: 'random' })
        }
      } else {
        return this.setState({ banner: 'random' })
      }
    }
  }

  renderRandomBanner = (content) => {
    const banners = content.random || false
    const rightBanner = (content.load && content.load.number) || false
    if (banners && banners.length) {
      const rightBannerInNumner = (rightBanner && Number(rightBanner)) || 0
      const filteredBanner = banners[rightBannerInNumner]
      let customAttributes = window.loginBannerDefault
      if (filteredBanner) {
        return (
          <div
            className={filteredBanner.class}
            key={filteredBanner.index}
            data-analytics-journey-group={this.renderCustomAttributesJourneyGroup(customAttributes)}
            data-analytics-personalization-journey={this.renderCustomAttributesJourney(customAttributes)}
          >
            <div className={filteredBanner.secondlevelClass}>
              <div className={filteredBanner.thirdlevelClass}>
                {
                  filteredBanner.colorbarcss &&
                  <style type="text/css" className="u-hidden" dangerouslySetInnerHTML={{ __html: filteredBanner.colorbarcss }} />
                }
                {
                  filteredBanner.bgcolorcss &&
                  <style type="text/css" className="u-hidden" dangerouslySetInnerHTML={{ __html: filteredBanner.bgcolorcss }} />
                }
                {
                  filteredBanner.bgimagecss &&
                  <style type="text/css" className="u-hidden" dangerouslySetInnerHTML={{ __html: filteredBanner.bgimagecss }} />
                }
                {
                  filteredBanner.heading && <h1 className="u-marginBottom2gu header-color u-textSizeMsrp2">
                    { filteredBanner.heading }
                  </h1>
                }
                <div className="Content--large u-marginTop1gu content-text">
                  {
                    filteredBanner.description && <section dangerouslySetInnerHTML={{ __html: filteredBanner.description }} />
                  }
                  {
                    filteredBanner.button &&
                     filteredBanner.button.text &&
                     <div className={filteredBanner.button.class}>
                       {
                         (() => {
                           let btnClass = 'Button Button--primary'
                           if (filteredBanner.button.color) {
                             btnClass = `Button Button--primary ${filteredBanner.button.color}`
                           }
                           return (<a className={btnClass} href={filteredBanner.button.link}>
                             { filteredBanner.button.text }
                           </a>)
                         })()
                       }
                     </div>
                  }
                  {
                    filteredBanner.disclosure &&
                      <div className={ filteredBanner.disclosureClass } dangerouslySetInnerHTML={{ __html: filteredBanner.disclosure }} />
                  }
                  {
                    filteredBanner.logoImageUrl &&
                      <div className="graphic-logo u-posAbsolute">
                        <img src={filteredBanner.logoImageUrl} />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return <div className="no-random-banners"/>
    }
  }

  renderCustomAttributesJourneyGroup = (props) => {
    return props.group || ''
  }

  renderCustomAttributesJourney = (props) => {
    return props['personalization-journey'] || ''
  }

  renderVariantBanner = (content) => {
    const banner = content.variant || false
    if (banner && banner.length) {
      const { pageName } = this.state
      let template = null
      let customAttributes = null
      if (pageName) {
        switch (pageName) {
        case 'credit-cards':
          template = (banner.length >= 1 && banner[0]) || false
          customAttributes = window.loginBannerDualCards
          break
        case 'visa-signature':
          template = (banner.length >= 2 && banner[1]) || false
          customAttributes = window.loginBannerVisaSignature
          break
        case 'premier-visa-signature':
          template = (banner.length >= 3 && banner[2]) || false
          customAttributes = window.loginBannerPremierVisaSignature
          break
        case 'ameriprise-rewards':
          template = (banner.length >= 4 && banner[3]) || false
          customAttributes = window.loginBannerAmpRewards
          break
        }
      }
      if (template) {
        const filteredBanner = template
        return (
          <div
            className={filteredBanner.class}
            key={filteredBanner.index}
            data-analytics-journey-group={this.renderCustomAttributesJourneyGroup(customAttributes)}
            data-analytics-personalization-journey={this.renderCustomAttributesJourney(customAttributes)}
          >
            <div className={filteredBanner.secondlevelClass}>
              <div className={filteredBanner.thirdlevelClass}>
                {
                  filteredBanner.colorbarcss &&
                  <style type="text/css" className="u-hidden" dangerouslySetInnerHTML={{ __html: filteredBanner.colorbarcss }} />
                }
                {
                  filteredBanner.bgcolorcss &&
                  <style type="text/css" className="u-hidden" dangerouslySetInnerHTML={{ __html: filteredBanner.bgcolorcss }} />
                }
                {
                  filteredBanner.bgimagecss &&
                  <style type="text/css" className="u-hidden" dangerouslySetInnerHTML={{ __html: filteredBanner.bgimagecss }} />
                }
                {
                  filteredBanner.heading && <h1 className="u-marginBottom2gu header-color u-textSizeMsrp2">
                    { filteredBanner.heading }
                  </h1>
                }
                <div className="Content--large u-marginTop1gu content-text">
                  {
                    filteredBanner.description && <section dangerouslySetInnerHTML={{ __html: filteredBanner.description }} />
                  }
                  {
                    filteredBanner.button &&
                     filteredBanner.button.text &&
                     <div className={filteredBanner.button.class}>
                       {
                         (() => {
                           let btnClass = 'Button Button--primary'
                           if (filteredBanner.button.color) {
                             btnClass = `Button Button--primary ${filteredBanner.button.color}`
                           }
                           return (<a className={btnClass} href={filteredBanner.button.link}>
                             { filteredBanner.button.text }
                           </a>)
                         })()
                       }
                     </div>
                  }
                  {
                    filteredBanner.disclosure &&
                      <div className={ filteredBanner.disclosureClass } dangerouslySetInnerHTML={{ __html: filteredBanner.disclosure }} />
                  }
                  {
                    filteredBanner.logoImageUrl &&
                      <div className="graphic-logo u-posAbsolute">
                        <img src={filteredBanner.logoImageUrl} />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return <div className="no-variant-banners"/>
    }
  }

  displayContent = () => {
    const logoutBannerStaticContent = document.querySelector('.LogoutBannerStatic')
    if (logoutBannerStaticContent) {
      logoutBannerStaticContent.classList && logoutBannerStaticContent.classList.remove('u-hidden')
    }
  }

  renderContent = (props) => {
    if (!props) {
      return null
    }

    let template
    switch (this.state.banner) {
    case 'random':
      template = this.renderRandomBanner(props)
      break
    case 'variant':
      template = this.renderVariantBanner(props)
      break
    default:
      template = this.renderRandomBanner(props)
    }
    this.displayContent()
    return template
  }

  render () {
    const {
      content
    } = this.props

    return (
      <Fragment>
        { content && content.banner && this.renderContent(content.banner) }
      </Fragment>
    )
  }
}
