import React from 'react'
import PropTypes from 'prop-types'
import './Promo.less'
import Spacer from '../Spacer'
import BrandIcon from '../BrandIcon'

const Promo = props => {
  const { content, testId } = props
  const { componentSpacing } = content

  let backgroundColor
  let imgBorderColor

  console.table(testId)

  // todo: transparent bgs
  switch (content.backgroundColor) {
  case 'purple-tint':
    backgroundColor = 'u-bgColorPurpleLight'
    imgBorderColor = '#EBE8EE'
    break
  case 'mint-tint':
    backgroundColor = 'u-bgColorMintLight'
    imgBorderColor = '#DEEEEF'
    break
  case 'lime-tint':
    backgroundColor = 'u-bgColorLimeLight'
    imgBorderColor = '#F2F7DD'
    break
  case 'stone':
    backgroundColor = 'u-bgColorStone'
    imgBorderColor = '#F3F2F1'
    break
  default:
    backgroundColor = 'white'
    imgBorderColor = '#FFFFFF'
  }

  const getContentClasses = (
    imgPosition,
    imgBgColor,
    backgroundImg,
    hasBorder,
    isArticle
  ) => {
    const classList = []

    if (backgroundImg) {
      classList.push('Promo-featuredImage')
    }
    if (imgPosition === 'left') classList.push('u-flexOrderFirst')
    if (hasBorder === 'true') {
      classList.push('u-border')
    }
    if (imgBgColor === 'matchbackground') {
      switch (imgPosition) {
      case 'left':
        classList.push(
          'u-flexOrderFirst Promo-image-leftBorder Promo-image-bgPadding'
        )
        break
      case 'right':
        classList.push('Promo-image-rightBorder Promo-image-bgPadding')
        break
      }
    }

    isArticle === 'true'
      ? classList.push('u-lg-size4of12')
      : classList.push('u-lg-size6of12')

    return classList.join(' ')
  }

  return (
    <Spacer spacing={componentSpacing}>
      <section
        className="Promo u-sizeConstrained"
        data-analytics-container="Promo"
      >
        <div className="u-flex u-flexAlignItemsStretch Grid u-flexAlignItemsCenter">
          <div
            data-testid={testId ? `${testId}-content` : null}
            className={`Promo-content u-flexAlignContentCenter u-paddingHoriz5gu ${backgroundColor} 
              ${
                content.isArticle === 'true'
                  ? 'u-lg-size4of12'
                  : 'u-lg-size6of12'
              }`}
          >
            <div className="u-paddingTop6gu">
              {content.iconImage && (
                <img
                  src={`${content.iconImage}`}
                  alt=""
                  className="Promo-customIcon"
                  testId={testId ? `${testId}-customIcon` : null}
                />
              )}
              {content.icon && (
                <BrandIcon
                  icon={content.icon}
                  className={content.iconColor}
                  testId={testId ? `${testId}-icon` : null}
                />
              )}
              {content.superHeadline && (
                <h5
                  className="u-paddingTop4gu u-textUppercase"
                  testId={testId ? `${testId}-superHeadline` : null}
                >
                  {content.superHeadline}
                </h5>
              )}
              {content.heading && (
                <h3
                  className="u-paddingVert2gu"
                  data-testid={testId ? `${testId}-heading` : null}
                >
                  {content.heading}
                </h3>
              )}
              {content.content && (
                <div
                  className="u-paddingBottom6gu"
                  data-testid={testId ? `${testId}-body` : null}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                ></div>
              )}
              {content.linkUrl && (
                <a
                  className={`Button u-marginBottom6gu ${
                    content.linkColor === 'white'
                      ? 'Button--secondary'
                      : 'Button--primary'
                  }`}
                  href={content.linkUrl}
                  // TODO: ADD analytics
                  data-analytics={''}
                  data-testid={testId ? `${testId}-button` : null}
                  // TODO: ADD TARGET
                >
                  {content.linkText}
                </a>
              )}
            </div>
          </div>
          <div
            data-testid={testId ? `${testId}-featuredImage` : null}
            // TODO: if video, dont use u-lg-size6of12 layout (need 16/9 aspect ratio)
            className={`Promo-content u-sm-flexOrderFirst u-md-flexOrderFirst ${getContentClasses(
              content.imagePostion,
              content.imageBackgroundColor,
              content.image,
              content.imageBorder,
              content.isArticle
            )}`}
            style={{
              backgroundImage: `url(${content.image})`,
              borderColor: imgBorderColor || ''
            }}
          ></div>
        </div>
      </section>
    </Spacer>
  )
}
Promo.propTypes = {
  content: PropTypes.shape({
    backgroundColor: PropTypes.string,
    isArticle: PropTypes.string,
    content: PropTypes.string,
    heading: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    image: PropTypes.string,
    iconImage: PropTypes.string,
    imageBackgroundColor: PropTypes.string,
    imageBorder: PropTypes.string,
    imagePostion: PropTypes.string,
    linkColor: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
    superHeadline: PropTypes.string,
    // TODO: add component spacing
    componentSpacing: PropTypes.oneOf(['bottom', 'top', 'full', 'nospace']),
    analytics: PropTypes.string
  }),
  testId: PropTypes.string
}

export default Promo
