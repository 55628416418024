import React, { useEffect, useState } from 'react'
import BrandIcon from '../BrandIcon/BrandIcon'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import './alert.css'

const ALERT_STATUS_RETENTION_PERIOD_DAYS = 60
const ALERT_STORAGE_KEY = 'alert-messages'
const ALERT_CLOSED_STATUS = 'CLOSED'

const Alert = props => {
  const { content, testId } = props
  const {
    icon,
    iconColor,
    body,
    bgColor,
    heading,
    linkHref,
    linkTarget,
    linkText,
    showCloseButton,
    analytics
  } = content

  const [isDismissed, setIsDismissed] = useState(false)
  const messageId = kebabCase(`${heading} + ${body}`.substring(0, 500))

  const classNames = []
  switch (bgColor) {
  case 'stone':
    classNames.push('u-bgColorStone')
    break
  case 'purple-light':
    classNames.push('u-bgColorPurpleLight')
    break
  case 'lime-light':
    classNames.push('u-bgColorLimeLight')
    break
  case 'mint-light':
    classNames.push('u-bgColorMintLight')
    break
  default:
    classNames.push('u-bgColorStone')
  }

  useEffect(() => {
    if (showCloseButton !== 'true') {
      return
    }

    const now = new Date()
    const storageRecord = localStorage.getItem(ALERT_STORAGE_KEY)

    if (storageRecord === null) {
      return
    }

    const dismissedMessages = JSON.parse(storageRecord)

    dismissedMessages.forEach(message => {
      if (message.id === messageId) {
        const storedDate = new Date(message.date)
        const differenceInMs = now - storedDate
        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24)
        if (differenceInDays <= ALERT_STATUS_RETENTION_PERIOD_DAYS) {
          setIsDismissed(true)
        }
      }

      // TODO: remove records older than ALERT_STATUS_RETENTION_PERIOD_DAYS
    })
  }, [setIsDismissed, messageId])

  function handleCloseButton () {
    setIsDismissed(true)

    const storageRecord = localStorage.getItem(ALERT_STORAGE_KEY)
    let dismissedMessages = []
    if (storageRecord !== null) {
      dismissedMessages = JSON.parse(storageRecord)
    }

    const dismissedDateTime = new Date()
    dismissedMessages.push({
      id: messageId,
      date: dismissedDateTime.toISOString(),
      status: ALERT_CLOSED_STATUS
    })
    localStorage.setItem(ALERT_STORAGE_KEY, JSON.stringify(dismissedMessages))
  }

  if (isDismissed) {
    return null
  }

  return (
    <section
      className={`Alert ${classNames.join(' ')}`}
      data-testid={testId ? `${testId}` : null}
    >
      <div className="u-sizeConstrained u-paddingVert4gu u-sm-hidden u-md-hidden">
        <div className="u-flex u-flexAlignItemsCenter u-sm-flexCol u-sm-flexAlignItemsStart">
          {icon && (
            <BrandIcon
              icon={icon}
              className={`Alert-icon u-marginRight2gu ${iconColor}`}
              style={{ height: '36px', width: '36px' }}
              testId={`${testId}-desktop-icon`}
            />
          )}
          <h3
            className="u-textColorNeutralGrey u-flexShrink0"
            data-testid={testId ? `${testId}-heading` : null}
          >
            {heading}
          </h3>
          <div
            className="Content u-marginHoriz4gu u-textColorNeutralGrey u-sm-marginVert4gu"
            dangerouslySetInnerHTML={{ __html: body }}
            data-testid={testId ? `${testId}-body` : null}
          ></div>
          <div className="u-flexExpandLeft u-sm-marginLeft0 u-sm-marginTop4gu u-flexShrink0">
            {linkHref && (
              <a
                className="Alert-button Button Button--small Button--secondary Button--secondary--action-blue u-marginHoriz4gu"
                href={linkHref}
                target={linkTarget}
                data-analytics={analytics}
                data-testid={testId ? `${testId}-link` : null}
              >
                {linkText}
              </a>
            )}
            {showCloseButton && (
              <button
                aria-hidden="true"
                className="Alert-closeIcon Icon u-textSizeMsrp4"
                data-testid={testId ? `${testId}-close-button` : null}
                onClick={() => {
                  handleCloseButton()
                }}
              >
                &#x2297;
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="u-sizeConstrained u-paddingVert4gu u-lg-hidden">
        <div className="u-flex u-flexCol u-alignItemsBaseline">
          <div className="u-flex u-flexAlignItemsStretch">
            {icon && (
              <BrandIcon
                icon={icon}
                className={`Alert-icon u-marginRight2gu ${iconColor}`}
                style={{ height: '36px', width: '36px' }}
                testId={`${testId}-mobile-icon`}
              />
            )}
            <h3 className="u-textColorNeutralGrey u-marginRight5gu">
              {heading}
            </h3>
            {showCloseButton && (
              <div className="u-flexExpandLeft">
                <button
                  aria-hidden="true"
                  className="Alert-closeIcon Icon u-textSizeMsrp4"
                  onClick={() => {
                    handleCloseButton()
                  }}
                >
                  &#x2297;
                </button>
              </div>
            )}
          </div>
          <div
            className="Content u-marginVert4gu u-textColorNeutralGrey"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
          {linkHref && (
            <a
              className="Alert-button Button Button--secondary Button--secondary--action-blue"
              href={linkHref}
              target={linkTarget}
              data-analytics={analytics}
            >
              {linkText}
            </a>
          )}
        </div>
      </div>
    </section>
  )
}

Alert.propTypes = {
  content: PropTypes.shape({
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    body: PropTypes.string,
    bgColor: PropTypes.oneOf([
      'stone',
      'purple-light',
      'lime-light',
      'mint-light',
      // TODO: deprecated old colors
      'orange',
      'purple'
    ]),
    heading: PropTypes.string,
    linkHref: PropTypes.string,
    linkTarget: PropTypes.string,
    linkText: PropTypes.string,
    showCloseButton: PropTypes.oneOf(['true', '']),
    analytics: PropTypes.string
  })
}
export default Alert
