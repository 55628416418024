import React from 'react'
import PropTypes from 'prop-types'
import './brand-icon.less'

const BrandIcon = props => {
  const { icon, fill, className, style, testId } = props

  const classList = ['BrandIcon']
  if (className) {
    classList.push(className)
  }
  if (fill) {
    classList.push('u-fillColorBrand')
  }

  return (
    <svg
      className={classList.join(' ')}
      aria-hidden="true"
      preserveAspectRatio="xMinYMin meet"
      style={style}
      data-testid={testId ? `${testId}-svg` : null}
    >
      <use
        href={`${window.AMPCOMPATH}images/brand-icons/icon-sprite.svg#${icon}`}
        data-testid={testId ? `${testId}-svg-use` : null}
      />
    </svg>
  )
}

BrandIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  fill: PropTypes.bool,
  className: PropTypes.string,
  testId: PropTypes.string
}

export default BrandIcon
